import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { fetchMovieById } from "../../api/oneApi.js";

const MovieMainBanner = () => {
  const { id } = useParams();
  const [movie, setMovie] = useState({});

  useEffect(() => {
    const getMovie = async () => {
      const movieData = await fetchMovieById(id);
      setMovie(movieData);
    };
    getMovie();
  }, [id]);

  return (
    <>
      {movie.trailer ? (
        <header className="video-header">
          <iframe
            width="100%"
            height="100%"
            controls="0"
            src={`https://www.youtube.com/embed/${movie.trailer}?controls=0&disablekb=1&fs=0&modestbranding=1&color=white&iv_load_policy=3`}
            title={movie.title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </header>
      ) : (
        <header className="movie-header">
          <img
            className="movie-header-img"
            src={movie.baner}
            alt={movie.title}
            title={movie.title}
          />
        </header>
      )}
    </>
  );
};

export default MovieMainBanner;
