import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Card from "./../card/Card";
import { fetchMoviesSoon } from "../../api/oneApi";

function MovieToDay() {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchMoviesSoon();
      setMovies(moviesData);
      setIsLoading(false);
    };
    getMovies();
  }, []);

  return (
    <section>
      <div className="wrapper">
        <div className="movies-today gap-1">
          <div className="heading-h1">
            <h1>Скоро на екрані</h1>
            <Link to={`/movie`}>Переглянути всі</Link>
          </div>
          <div className="card-list">
            {isLoading
              ? "Завантаження..."
              : movies.map((movie) => {
                  return (
                    <Card
                      key={movie.id}
                      id={movie.id}
                      title={movie.title}
                      trailer={movie.trailer}
                      seances={movie.seances}
                      original={movie.original}
                      year={movie.year}
                      description={movie.description}
                      poster={movie.poster}
                      baners={movie.baner}
                      directors={movie.directors}
                      genres={movie.genres}
                      actors={movie.actors}
                      country={movie.country}
                      age={movie.age}
                      display={movie.display}
                      duration={movie.duration}
                      rental={movie.rental}
                      details_img={movie.details_img}
                      ua_premiere = {movie.ua_premiere}
                    />
                  );
                })}
          </div>

          <Link to={`/movie`} className="movie-more-mobile">
            Переглянути всі
          </Link>
        </div>
      </div>
    </section>
  );
}

export default MovieToDay;
