import { Helmet } from "react-helmet";

import ActionBlock from "../components/actionblock/ActionBlock";
import MovieRandomBanner from "./../components/movieRandomBanner/MovieRandomBanner";

const Action = () => {
  return (
    <>
    <Helmet>
        <title>
          Акції » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <MovieRandomBanner />
      <ActionBlock />
    </>
  );
};

export default Action;
