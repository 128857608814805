import cinemaImg from "./../../img/images/cinemaImg.png";

const AboutCinema = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="about-wrapper">
          <div className="heading-h1">
            <h1>Кінотеатр Дружба</h1>
          </div>

          <div className="cinema-img-wrapper">
            <img
              className="cinema-img-main"
              src={cinemaImg}
              alt="Кінотеатр Дружба"
              title="Кінотеатр дружба"
            />
          </div>

          <div className="about-cinema-wrapper">
            <div className="heading-h2">
              <h2>Про кінотеатр</h2>
            </div>
            <div className="text-cinema-wrapper">
              <p>
                Кінотеатр Дружба побудовано в 1972 році. У всі часи кінотеатр
                користувався в жителів міста Суми репутацією справжнього центру
                культури та відпочинку. Про зручність кіноглядача піклуються ще
                до порогу кінотеатру. Перед кінотеатром велика проща з квітучими
                клумбами. Оформлені стенди “Сьогодні” та “Незабаром”, що
                запрошують сумчан та гостей міста в кіно. Очікування сеансів в
                Дружбі завжди приємне. Просторе фойє кінотеатру, зимовий сад з
                пальмами, кактусами, папороттю та іншими екзотичними квітами.
                Дітлахів найбільше приваблюють акваріуми з рибками та
                черепахами. На другому поверсі можливо послухати спів птахів!
              </p>
              <p>
                Глядацький зал розраховано на 396 комфортабельних місць! Влітку
                2013 року оновлено підлогу та сидіння. Форма зали та стелі,
                дерев’яне покриття стін – створюють неймовірну акустику в залі!
                Сучасне обладнання Dolby Digital Surround забезпечує стабільно
                високу якість звучання. Найсучасніший проектор Barco 32B в
                поєднанні з фірмовими технологіями Dolby Cinema 3D створюють
                неймовірно чутке та яскраве зображення та неймовірні 3D ефекти!
                Потужні кондиціонери створюють в залі мікроклімат, що не
                залежить від зовнішніх температурних коливань, подають до зали
                свіже повітря.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCinema;
