import { Helmet } from "react-helmet";

import Contact from "./../components/contact/Contact";
import CinemaHeader from "../components/cinemaheader/CinemaHeader";

const Contacts = () => {
  return (
    <>
    <Helmet>
        <title>
          Контакти » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <CinemaHeader />
      <Contact />
    </>
  );
};

export default Contacts;
