import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CardNews from "../cardnews/CardNews";
import { fetchNews } from "../../api/oneApi";

const HomeNews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchNews();
      setMovies(moviesData);
      setIsLoading(false);
    };

    getMovies();
  }, []);

  return (
    <section>
      <div className="wrapper">
        <div className="home-news gap-2">
          <h1 className="news-main-headering">Новини</h1>
          <div className="home-main-news">
            {isLoading
              ? "Завантаження..."
              : movies.map((movie) => (
                  <CardNews
                    key={movie.id}
                    id={movie.id}
                    title={movie.title}
                    created={movie.created}
                    description={movie.description}
                    content={movie.content}
                    img_news={movie.img}
                    details_img={movie.details_img}
                  />
                ))}
          </div>
          <Link className="more-news" to={`/news`}>
            Переглянути всі
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeNews;
