import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { fetchMovieById } from "../../api/oneApi.js";

const ActorsCarusel = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const { id } = useParams();
  const [movie, setMovie] = useState({});

  useEffect(() => {
    const getMovie = async () => {
      const movieData = await fetchMovieById(id);
      setMovie(movieData);
    };
    getMovie();
  }, [id]);

  const { title, original, actors, details_img } = movie;

  return (
    <section>
      <div className="wrapper">
        <div className="actors-carusel-wrapper">
          <div className="actors-h-group-wrapper">
            <h2 className="actors-h-info">У головних ролях</h2>
            <div className="actor-group">
              {actors &&
                actors.map((actor) => (
                  <div key={actor.name} className="actor-info-img-wrapper">
                    <img
                      className="actor-img"
                      src={actor.img}
                      alt={actor.name}
                    />
                    <div className="actor-info">
                      <p className="actor-name">{actor.name}</p>
                      {actor.age ? (
                        <span className="actor-age">{actor.age} років</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="details-carusel">
            <div className="swiper-main">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper4"
              >
                {details_img &&
                  details_img.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt={`${original}-${index}`}
                        title={title}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="details-thubms">
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                watchSlidesProgress={true}
                modules={[Navigation, Thumbs]}
                className="mySwiper3"
              >
                {details_img &&
                  details_img.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt={`${original}-${index}`}
                        title={title}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActorsCarusel;
