import { Helmet } from "react-helmet";


import AboutCinema from "../components/aboutcinema/AboutCinema";
import AboutTechnology from "../components/abouttechnology/AboutTechnology";
import CinemaHeader from "../components/cinemaheader/CinemaHeader";

import SocialResponsibility from "../components/socialresponsibility/SocialResponsibility";
import WorldCompany from "../components/worldcompanies/WorldCompanies";
import CinemaAlbum from "../components/cinemaalbum/cinemaAlbum";

const About = () => {
  return (
    <>
      <Helmet>
        <title>
          Про кінотеатр » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <CinemaHeader />
      <div className="about-all-wrapper">
        <AboutCinema />

        <CinemaAlbum />

        <WorldCompany />

        <AboutTechnology />

       

        <SocialResponsibility />
      </div>
    </>
  );
};

export default About;
