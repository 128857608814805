import axios from 'axios';

const fetchMovieById = async (id) => {
    const response = await axios.get(`http://kinosumy.freemyip.com:8001/ua/api/movie/${id}`);
    return response.data[0];
};


const fetchMovies = async () => {
   
        const response = await axios.get('http://kinosumy.freemyip.com:8001/ua/api/now/');
        return response.data;
};


const fetchMoviesSoon = async () => {
   
    const response = await axios.get('http://kinosumy.freemyip.com:8001/ua/api/soon/');
    return response.data;
};


const fetchBanners = async () => {
   
    const response = await axios.get('http://kinosumy.freemyip.com:8001/en/api/moviesprice/');
    return response.data;
};

const fetchNews = async () => {
   
    const response = await axios.get('http://kinosumy.freemyip.com:8001/ua/api/newses3/');
    return response.data;
};

const fetchNewsById = async (id) => {
   
    const response = await axios.get(`http://kinosumy.freemyip.com:8001/ua/api/news/${id}?format=json`);
    return response.data[0];
};




const fetchAlbum = async () => {
   
    const response = await axios.get(`http://kinosumy.freemyip.com:8001/ua/api/drugbaimgs/`);
    return response.data;
};

export { fetchMovieById };
export { fetchMovies };
export { fetchBanners };
export {fetchMoviesSoon};
export { fetchNews };
export { fetchNewsById };

export { fetchAlbum };