import React, { useState, useEffect } from "react";

import Movieblock from "../movieblock/Movieblock";
import { fetchMovies } from "../../api/oneApi";

function MovieToDayDetail() {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchMovies();
      setMovies(moviesData);
      setIsLoading(false);
    };

    getMovies();
  }, []);

  return (
    <section>
      <div className="wrapper">
        <div className="heading-h1">
          <h1>Сьогодні на екрані</h1>
        </div>

        <div className="movie-list">
          {isLoading
            ? "Завантаження..."
            : movies.map((movie) => {
                return (
                  <Movieblock
                    key={movie.id}
                    id={movie.id}
                    title={movie.title}
                    trailer={movie.trailer}
                    seances={movie.seances}
                    original={movie.original}
                    year={movie.year}
                    description={movie.description}
                    poster={movie.poster}
                    baners={movie.baner}
                    directors={movie.directors}
                    genres={movie.genres}
                    actors={movie.actors}
                    country={movie.country}
                    age={movie.age}
                    display={movie.display}
                    duration={movie.duration}
                    rental={movie.rental}
                    details_img={movie.details_img}
                  />
                );
              })}
        </div>
      </div>
    </section>
  );
}

export default MovieToDayDetail;
