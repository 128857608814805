import Dolby from "./../../img/images/dolby.png";
import Dolby3d from "./../../img/images/dolby 3d.png";
import Barco from "./../../img/images/barco.png";
import Kinoprokat from "../../img/images/kinoprokat.png";

const AboutTechnology = () => {
  return (
    <div className="wrapper">
      <div className="technology-wrapper">
        <div className="header-technology-wrapper">
          <div className="heading-h2">
            <h2>Про технології</h2>
          </div>
          <p>
            Кінотеатр Дружба використовує лише найсучасніше та найякісніше
            обладнання! На озброєнні кінотеатру технології Dolby Digital EX та
            Dolby Cinema 3D, і найновіше обладнання – сучасний цифровий проектор
            Barco (Бельгія) – BARC0 DP2K-32B, та найпотужніші кіносервери від
            французького виробника – Doremi Cinema.
          </p>
        </div>

        <div className="technology-info-group">
          <div className="technology-info-wrapper">
            <div className="technology-info ord-1">
              <div className="heading-h3">
                <h3>Dolby Digital EX</h3>
              </div>
              <p>
                Dolby Digital EX – є розвитком формату Dolby Digital, до якого
                додано ще один звуковий канал для центральної задньої колонки,
                розміщений безпосередньо за слухачем. Цей формат реалізується за
                допомогою акустичної системи 7.1.
              </p>
            </div>
            <div className="technology-info ord-2">
              <img
                src={Dolby}
                alt="Dolby Digital EX"
                title="Dolby Digital EX"
              />
            </div>
          </div>

          <div className="technology-info-wrapper">
            <div className="technology-info ord-3">
              <img src={Dolby3d} alt="Dolby 3D" title="Dolby 3D" />
            </div>
            <div className="technology-info ord-4">
              <div className="heading-h3">
                <h3>Dolby 3D</h3>
              </div>
              <p>
                Dolby 3D – це система створення стереоскопічного ефекту за
                допомогою змішування зображень з різними довжинами хвиль (англ.
                stereoscopic visualisation tool by wavelength multiplex
                imaging). Dolby 3D Digital Cinema – система цифрового
                тривимірного кінопоказу для демонстрації 3D-контента. У даній
                системі зображення піддається спеціальній обробці в Dolby Show
                Player, яка здійснюється в режимі реального часу. За допомогою
                пасивних окулярів Dolby 3D Cinema глядачі отримують якісне 3D
                зображення!
              </p>
            </div>
          </div>

          <div className="technology-info-wrapper">
            <div className="technology-info ord-5">
              <div className="heading-h3">
                <h3>Barco</h3>
              </div>
              <p>
                Barco (Бельгія) – флагманська модель Barco DP2K-32B володіє
                максимально можливою яскравістю серед цифрових кінопроекторів.
                Завдяки високій світловіддачі модель DP2K-32B є унікальним у
                своєму роді 3D-проектором, призначений для кінотеатрів з
                великими екранами. Проектор здатний демонструвати надзвичайно
                яскраві, насичені і висококонтрастні зображення і створює у
                аудиторії ілюзію повного занурення.
              </p>
            </div>
            <div className="technology-info ord-6">
              <img src={Barco} alt="Barco" title="Barco" />
            </div>
          </div>

          <div className="technology-info-wrapper">
            <div className="technology-info ord-7">
              <img
                src={Kinoprokat}
                alt="KINOPROKAT 3D POLAR"
                title="KINOPROKAT 3D POLAR"
              />
            </div>
            <div className="technology-info ord-8">
              <div className="heading-h3">
                <h3>KINOPROKAT 3D POLAR</h3>
              </div>
              <p>
                3D системама нового покоління Kinoprokat 3D Polar. Яка допомагає
                вивести тривимірне зображення на новий рівень якості
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTechnology;
