import { Link } from "react-router-dom";

import Discount from "./../../img/images/discount.png";
import Loyalty from "./../../img/images/loyalty.png";
import News from "./../../img/images/news.png";

const LoyaltyProgramBlock = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="loyalty-info-wrapper">
          <div className="loyalty-info">
            <img src={Discount} alt="" title="" />

            <div className="loyalty-info-h">
              <Link to={"/action"}>
                <h1> Акції / Знижки</h1>
              </Link>
            </div>
          </div>

          <div className="loyalty-info">
            <img src={Loyalty} alt="" title="" />

            <div className="loyalty-info-h">
            <Link to={"#"}>
              <h1> Програма лояльності</h1>
              </Link>
            </div>
            
          </div>

          <div className="loyalty-info">
            <img src={News} alt="" title="" />

            <div className="loyalty-info-h">
              <Link to={"/news"}>
                <h1> Новини</h1>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoyaltyProgramBlock;
