import { Helmet } from "react-helmet";

import MainNews from "../components/mainnews/MainNews";
import MovieRandomBanner from "../components/movieRandomBanner/MovieRandomBanner";

const News = () => {
  return (
    <>
    <Helmet>
        <title>
          Новини » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <MovieRandomBanner />
      <MainNews />
    </>
  );
};

export default News;
