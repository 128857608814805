import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function CardNews({ id, title, description, img_news }) {
  return (
    <Link to={`/news-details/${id}`} state={{ id }} className="news-link">
      <div className="news-card">
        <img src={img_news} alt={title} title={title} />
        <div className="news-info">
          <p className="news-head">{title}</p>
          <p className="news-description">{description}</p>
        </div>
      </div>
    </Link>
  );
}

CardNews.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  details_img: PropTypes.array.isRequired,
};

export default CardNews;
