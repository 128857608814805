import React, { useState, useEffect } from "react";

import CardNews from "../cardnews/CardNews";
import { fetchNews } from "../../api/oneApi";

const MainNews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchNews();
      setMovies(moviesData);
      setIsLoading(false);
    };

    getMovies();
  }, []);

  return (
    <section>
      <div className="wrapper">
        <div className="home-news">
          <h2 className="news-main-headering">Новини</h2>
          <div className="main-news">
            {isLoading
              ? "Завантаження..."
              : movies.map((movie) => {
                  return (
                    <CardNews
                      key={movie.id}
                      id={movie.id}
                      title={movie.title}
                      created={movie.created}
                      description={movie.description}
                      content={movie.content}
                      img_news={movie.img}
                      details_img={movie.details_img}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainNews;
