import { Helmet } from "react-helmet";

import MoviePrice from "../components/movieprice/MoviePrice";
import MovieRandomBanner from "./../components/movieRandomBanner/MovieRandomBanner";

const Sessions = () => {
  return (
    <>
      <Helmet>
        <title>Ціни та розклад сеансів » Кінотеатр Дружба - Кінотеатр Дружба – kino.sumy.ua</title>
      </Helmet>
      <MovieRandomBanner />
      <MoviePrice />
    </>
  );
};

export default Sessions;
