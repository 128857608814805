import React from "react";
import { Helmet } from "react-helmet";

import Moviebanner from "./../components/moviebanner/Moviebanner";
import MovieToDay from "../components/movietoday/MovieToDay";
import HomeNews from "../components/homenews/HomeNews";
import MovieSoon from "../components/moviesoon/MovieSoon";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Кінотеатр Дружба - Кінотеатр Дружба - kino.sumy.ua</title>
      </Helmet>
      <Moviebanner />

      <MovieToDay />

      <HomeNews />

      <MovieSoon />
    </>
  );
};

export default Home;
