import { Helmet } from "react-helmet";

import AttentionInfo from "../components/attentioninfo/AttentionInfo";
import CinemaHeader from "../components/cinemaheader/CinemaHeader";

const Attention = () => {
  return (
    <>
    <Helmet>
        <title>
          УВАГА! УВАГА! » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <CinemaHeader />
      <AttentionInfo />
    </>
  );
};

export default Attention;
