import cinemaBaner from "./../../img/images/cinemaBaner.png";

const CinemaHeader = () => {
  return (
    <header className="cinema-header">
      <img
        className="cinema-header-img"
        src={cinemaBaner}
        alt="Кінотеатр Дружба"
        title="Кінотеатр дружба"
      />
    </header>
  );
};

export default CinemaHeader;
