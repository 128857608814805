import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { fetchNewsById } from "../../api/oneApi.js";

const NewsCarusel = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const { id } = useParams();
  const [news, setNews] = useState({});

  useEffect(() => {
    const getNews = async () => {
      const newsData = await fetchNewsById(id);
      setNews(newsData);
    };
    getNews();
  }, [id]);

  const { details_img, title } = news;

  return (
    <section>
      <div className="wrapper">
        <div className="news-carusel-wrapper">
          <div className="swiper-main-news">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper6"
            >
              {details_img &&
                details_img.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img src={img} alt={`${title}-${index}`} title={title} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          <div className="details-thubms-news">
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={4}
              watchSlidesProgress={true}
              modules={[Navigation, Thumbs]}
              className="mySwiper5"
            >
              {details_img &&
                details_img.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img src={img} alt={`${title}-${index}`} title={title} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsCarusel;
