import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import MovieRandomBanner from "../components/movieRandomBanner/MovieRandomBanner";

const Map = () => {
    return (
        <>
            <Helmet>
                <title>Карта сайту » Кінотеатр Дружба - Кінотеатр Дружба – kino.sumy.ua</title>
                <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
            </Helmet>
            <MovieRandomBanner />
            <section>
                <div className="wrapper">

                    <div className="map-wrapper">
                        <div className="heading-h1">
                            <h1>Карта сайту</h1>
                        </div>

                        <div className="map-link">
                            <ul>
                                <li><Link to={"/"}>Головна сторінка</Link></li>
                                <li><Link to={"/movie"}>Фільми</Link></li>
                                <li><Link to={"/sessions"}>Ціни та сеанси</Link></li>
                                <li><Link to={"/about"}>Про Кінотеатр</Link></li>
                                <li>
                                    <div >
                                        <Link to={"/viewer"}>Глядачеві</Link>
                                    </div>
                                    <ul className="map-link-in">
                                        <Link to={"/action"}>Акції та знижки</Link>
                                        <Link to={"/news"}>Новини</Link>
                                    </ul>
                                </li>
                                <li><Link to={"/contacts"}>Контакти</Link></li>
                                <li><Link to={"/attention"}>Увага</Link></li>
                                <li><Link to={"/sitemap"}>Карта Сайту</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Map;