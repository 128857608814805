import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { fetchNewsById } from "../../api/oneApi";

const NewsDetail = () => {
  const { id } = useParams();
  const [news, setNews] = useState({});

  useEffect(() => {
    const getNews = async () => {
      const newsData = await fetchNewsById(id);
      setNews(newsData);
    };
    getNews();
  }, [id]);

  return (
    <>
    <Helmet>
        <title>{`${news.title} » Кінотеатр Дружба - Кінотеатр Дружба – kino.sumy.ua `}</title>
      </Helmet>
    <section>
      <div className="wrapper">
        <div className="header-news-wrapper">
          <div class="news_main_img">
            <img src={news.img} alt={news.title} title={news.title} />
          </div>
          <article>
            <header>
              <h1>
                <div class="headline-1">{news.title}</div>
              </h1>
            </header>
            <hr />
            <section className="section">
              <p dangerouslySetInnerHTML={{ __html: news.content }}></p>
            </section>
          </article>
        </div>
      </div>
    </section>
    </>
  );
};

export default NewsDetail;
