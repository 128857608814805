// import MovieRandomBanner from "../components/movieRandomBanner/MovieRandomBanner";
import Error from "../img/images/img_404.png"
import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <>
        {/* <MovieRandomBanner /> */}
      <section>
            <div className="wrapper">
                <div className="error-wrapper error-size">

                    <div className="error-block-text">
                            <h1>ООООООЙЙЙЙЙ!!!!!</h1>
                            <h3>Здається кіна не буде!</h3>
                            <p>Поверніться на головну сторінку, там точно знайдете щось цікавеньке</p>
                           
                            <Link to={`/`} >
                                <button className="button-link">На головну</button>
                            </Link>
                    </div>

                    <div className="error-block-img error-size">
                            <img src={Error} alt="Кінотеатр Дружба помилка 404"  title="Кінотеатр Дружба помилка 404"/>
                    </div>

                </div>
            </div>

      </section>
</>

     );
}
 
export default NotFound;