/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { fetchMovieById } from "../../api/oneApi.js";

const Details = () => {
  const { id } = useParams();
  const [movie, setMovie] = useState({});
  const [displayMode, setDisplayMode] = useState("");

  useEffect(() => {
    const getMovie = async () => {
      const movieData = await fetchMovieById(id);
      setMovie(movieData);
    };
    getMovie();
  }, [id]);

  const {
    title,
    age,
    year,
    poster,
    original,
    directors,
    country,
    genres,
    actors,
    display,
    duration,
    description,
  } = movie;

  useEffect(() => {
    if (display === 2) {
      setDisplayMode("3D");
    } else if (display === 1) {
      setDisplayMode("2D");
    }
  }, [display]);
  return (
    <>
      <Helmet>
        <title>{`${title} » Кінотеатр Дружба - Кінотеатр Дружба – kino.sumy.ua`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section>
        <div className="movie-wrapper">
          <div className="heading-h1">
            <h1>{title}</h1>
          </div>
          <div className="movie-img-description">
            <img className="movie-img" src={poster} alt={title} title={title} />

            <div className="movie-description-group">
              <div className="movie-description">
                <p className="movie-desc-index">Вік: </p>
                <p className="movie-desc-info">{age}+ </p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Дата виходу: </p>
                <p className="movie-desc-info">{year}</p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Оригінальна назва: </p>
                <p className="movie-desc-info">{original}</p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Режисер: </p>

                <p className="movie-desc-info">
                  {directors &&
                    directors.map((director, index) => (
                      <span key={director.name}>
                        {director.name}
                        {index !== directors.length - 1 && " "}
                      </span>
                    ))}{" "}
                </p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Країна/Студія: </p>
                <p className="movie-desc-info">{country}</p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Жанр: </p>
                <p className="movie-desc-info">{genres && genres.join(", ")}</p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">У головних ролях: </p>
                <p className="movie-desc-info">
                  {actors &&
                    actors.map((actor, index) => (
                      <span key={actor.name}>
                        {actor.name}
                        {index !== actors.length - 1 && ", "}
                      </span>
                    ))}
                </p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Мова дубляжу: </p>
                <p className="movie-desc-info">українська</p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Режим показу: </p>
                <p id="display_mode_detail" className="movie-desc-info">
                  {displayMode}
                </p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Тривалість: </p>
                <p className="movie-desc-info">
                  {Math.floor(duration / 60)} год {duration % 60} хв
                </p>
              </div>

              <div className="movie-description">
                <p className="movie-desc-index">Опис: </p>
                <p className="movie-desc-info">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
