import ShelterMap from "./../../img/images/sheltermap.png";

const AttentionInfo = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="wrapper-attention">
          <div className="heading-h1">
            <h1>Увага! Увага!</h1>
          </div>

          <div className="info-attention">
            <p>
              Працівники та відвідувачі кінотеатру, щойно почувши сигнал
              <span className="attColor"> «ПОВІТРЯНА ТРИВОГА»</span> не повинні
              нехтувати правилами поведінки під час ВОЄННОГО
              <span className="attColor"> СТАНУ</span> та прослідувати в
              <span className="attColor"> УКРИТТЯ.</span>
            </p>

            <p>
              Найближче укриття знаходиться в приміщенні сумської
              загальноосвітньої школи №18
            </p>
            <p>м.Суми, вул. Леваневського, 8</p>

            <div className="img-shelter-map">
              <img src={ShelterMap} alt="Мапа укриття" title="Мапа укриття" />
            </div>

            <p className="attention-position-center">
              Під час повітряної тривоги можно скористатися сховищем в
              підвальному приміщенні кінотеатру, а квиток на сеанс обміняти.
            </p>

            <p className="attention-position-right">
              Адміністрація ТОВ «КІНОТЕАТР «ДРУЖБА»
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AttentionInfo;
