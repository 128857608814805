import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import {FreeMode, Navigation, Thumbs } from "swiper/modules";
import { fetchAlbum } from "../../api/oneApi.js";


const CinemaAlbum = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAlbum();
                if (data && data.length > 0 && Array.isArray(data[0].img)) {
                    setImages(data[0].img);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <section>
            <div className="wrapper">
                <div className="album-carusel-wrapper">
                    <div className="swiper-album">
                        <Swiper
                            style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                            }}
                            loop={true}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{
                                swiper:
                                    thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                            }}
                           
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiperAlbum2"
                        >
                            {images.map((imageUrl, index) => (
                                <SwiperSlide key={index}>
                                    <img src={imageUrl} alt={`Slide ${index}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="details-thubms-album">
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={4}
                            watchSlidesProgress={true}
                            modules={[Navigation, Thumbs]}
                            className="mySwiperAlbum"
                        >
                            {images.map((imageUrl, index) => (
                                <SwiperSlide key={index}>
                                    <img src={imageUrl} alt={`Thumb ${index}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CinemaAlbum;