import React from "react";

import MovieRandomBanner from "../components/movieRandomBanner/MovieRandomBanner.js";
import NewsDetail from "../components/newsdetails/NewsDetail.js";
import NewsCarusel from "../components/newscarusel/newsCarusel.js";

const NewsDetails = () => {
  return (
    <>
      <MovieRandomBanner />
      <div className="news-wrapper">
      <NewsDetail />
      <NewsCarusel />
      </div>
    </>
  );
};

export default NewsDetails;
