import React from "react";
import { Helmet } from "react-helmet";

import MovieRandomBanner from "../components/movieRandomBanner/MovieRandomBanner";
import MovieToDayDetail from "../components/movieToDayDetail/MovieToDayDetail";
import MovieToSoonDetail from "../components/movieToSoonDetail/MovieToSoonDetail";

const Movie = () => {
  return (
    <>
      <Helmet>
        <title>Сьогодні | Скоро на екрані » Кінотеатр Дружба - Кінотеатр Дружба – kino.sumy.ua</title>
      </Helmet>
      <MovieRandomBanner />

      <div className="movie-list-wrapper">
        <div className="movie-list-menu">
          <a href="#ToDay">
            <p className="movie-list-menu-header1">Сьогодні на екрані</p>
          </a>
          <span>|</span>
          <a href="#ToSoon">
            <p className="movie-list-menu-header2">Скоро на екрані</p>
          </a>
        </div>
        <div id="ToDay">
          <MovieToDayDetail />
        </div>
        <div id="ToSoon">
          <MovieToSoonDetail />
        </div>
      </div>
    </>
  );
};

export default Movie;
