import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Autoplay, EffectFade, Navigation, Thumbs } from "swiper/modules";

import { fetchBanners } from "../../api/oneApi";

const App = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchBanners();
      setMovies(moviesData);
    };

    getMovies();
  }, []);

  return (
    <section>
      <div className="wrapper-banner">
        <Swiper
          
          effect={"fade"}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[Autoplay, EffectFade, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {movies.map((movie, index) => (
            <SwiperSlide key={index}>
              <img src={movie.baner} alt={movie.title} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="swiper-thumb">
          <Swiper
            onSwiper={setThumbsSwiper}
            
            spaceBetween={10}
            slidesPerView={4}
            slidesPerGroup={1}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[Navigation, Thumbs]}
            className="mySwiper"
          >
            {movies.map((movie, index) => (
              <SwiperSlide key={index}>
                <img src={movie.poster} alt={movie.title} title={movie.title} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default App;
