import { NavLink } from "react-router-dom";
import React, { useState } from "react";

import logo from "./../../img/icons/logo.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = () => {
    setIsOpen(false);
  };

  const activeLink = "actives";
  const normalLink = "normal";

  return (
    <nav id="navbar">
      <NavLink to="/" className="logo">
        <img src={logo} alt="Logo" />
      </NavLink>
      <div
        className={`hamburger ${isOpen ? "toggle" : ""}`}
        onClick={toggleNavbar}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Головна
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/movie"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Фільми
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/sessions"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Ціни та сеанси
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Про кінотеатр
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/viewer"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Глядачеві
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contacts"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Контакти
          </NavLink>
        </li>
        <li className="link-gap">
          <NavLink
            to="/attention"
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            onClick={handleNavLinkClick}
          >
            Увага
          </NavLink>
        </li>
       
      </ul>
    </nav>
  );
};

export default Navbar;
