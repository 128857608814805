import { Helmet } from "react-helmet";

import LoyaltyProgramBlock from "../components/loyaltyprogramblock/LoyaltyProgramBlock";
import ViewerInfo from "../components/viewerinfo/ViewerInfo";
import MovieRandomBanner from "./../components/movieRandomBanner/MovieRandomBanner";

const Viewer = () => {
  return (
    <>
     <Helmet>
        <title>
          Акції та програма лояльності » Кінотеатр Дружба - Кінотеатр Дружба –
          kino.sumy.ua
        </title>
      </Helmet>
      <MovieRandomBanner />

      <div className="viewer-wrapper">
      <LoyaltyProgramBlock />

      <ViewerInfo />
      </div>
    </>
  );
};

export default Viewer;
