import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';

import './styles/main.css'

import ScrollToTop from "./utils/scrollToTop";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/Home";
import Movie from "./pages/Movies";
import Sessions from "./pages/Sessions";
import About from "./pages/About";
import Viewer from "./pages/Viewer";
import Contacts from "./pages/Contacts";
import Attention from "./pages/Attention";
import Details from "./pages/Details";
import Map from "./pages/Map";

import NewsDetails from './pages/NewsDetails';
import Action from './pages/Action';
import News from './pages/News';
import NotFound from './pages/NotFound';

function App () {
  return ( 
    <Router>
      <ScrollToTop />
        <Navbar />

        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/movie" element={<Movie />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/about" element={<About />} />
          <Route path="/viewer" element={<Viewer />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/attention" element={<Attention />} />

          <Route path="/details/:id" element={<Details />} />
          <Route path="/news-details/:id" element={<NewsDetails/>} />
         
          <Route path="/action" element={<Action/>} />
          <Route path="/news" element={<News/>} />   
          <Route path="/sitemap" element={<Map />} />

          <Route path="*" element={<NotFound />} />
        </Routes>       
 
        <Footer />
      </Router>
   );
}
 
export default App;