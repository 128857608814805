import React, { useState, useEffect } from "react";

import { fetchMovies } from "../../api/oneApi";
import PriceBlock from "../priceblock/PriceBlock";

function MoviePrice() {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const getMovies = async () => {
      const moviesData = await fetchMovies();
      setMovies(moviesData);
      setIsLoading(false);
    };

    getMovies();
  }, []);

  return (
    <div className="wrapper">
      <div className="priceblock-list">
        {isLoading
          ? "Завантаження..."
          : movies.map((movie) => {
              return (
                <PriceBlock
                  key={movie.id}
                  id={movie.id}
                  title={movie.title}
                  trailer={movie.trailer}
                  seances={movie.seances}
                  original={movie.original}
                  year={movie.year}
                  description={movie.description}
                  poster={movie.poster}
                  baners={movie.baner}
                  directors={movie.directors}
                  genres={movie.genres}
                  actors={movie.actors}
                  country={movie.country}
                  age={movie.age}
                  display={movie.display}
                  duration={movie.duration}
                  rental={movie.rental}
                  details_img={movie.details_img}
                />
              );
            })}
      </div>
    </div>
  );
}

export default MoviePrice;
