import React, { useState, useEffect } from "react";
import axios from "axios";

const MovieRandomBanner = () => {
  const [randomMovie, setRandomMovie] = useState({ title: "", baner: "" });

  useEffect(() => {
    const getRandomMovie = async () => {
      const movies1 = await axios.get(
        "http://kinosumy.freemyip.com:8001/en/api/banersall/?format=json"
      );
      const movies = movies1.data;

      const randomIndex = Math.ceil(Math.random() * movies.length - 1);
      const randomMovie = movies[randomIndex];

      setRandomMovie({ title: randomMovie.title, baner: randomMovie.baner });
    };

    getRandomMovie();
  }, []);

  return (
    <header className="movie-header-random">
      <img
        className="movie-header-random-img"
        src={randomMovie.baner}
        alt={randomMovie.title}
        title={randomMovie.title}
      />
    </header>
  );
};

export default MovieRandomBanner;
