
import QrCode from "./../../img/images/qr-code.svg";
const ViewerInfo = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="info-wrapper">
          <div className="small-wrapper">
            <div className="heading-h3">
              <h3>Повернення квитка</h3>
            </div>

            <div className="text-info">
              <p>
                Якщо ви залишаєте кінотеатр під час повітряної тривоги або з
                іншої причини, ви{" "}
                <span> за бажанням, можете повернути кошти за квиток </span> або
                <span> перебронювати</span> квиток на інший сеанс.
              </p>

              <p>
                <span> За бажанням, ви можете залишити приміщення</span>{" "}
                кінотеатру
                <span> під час повітряної тривоги!</span>{" "}
              </p>

              <p>
                <span>Кошти за квиток</span> можна повернути, або{" "}
                <span>перебронювати</span> квиток на інший сеанс, протягом
                перших 30 хвилин після початку перерваного перегляду!
              </p>

              <p className="text-gap">
                Квиток за перерваний сеанс вважається недійсним
              </p>
            </div>
          </div>

          <div className="small-wrapper">
            <div className="heading-h3">
              <h3>Перебої з освітленням</h3>
            </div>

            <div className="text-info">
              <p>Шановні глядачі!</p>

              <p>
                <span> Можливі перебої з освітленням!</span>{" "}
              </p>

              <p>
                В період відключень електроенергії, обладнання, за відсутності
                електропостачання, переводиться на резервні джерелеа живлення!
              </p>

              <p>
                При переході, слідкуйте за інформацією на екрані та зберігайте
                спокій!
              </p>
            </div>
          </div>

          <div className="attention-h-wrapper">
            <div className="heading-h3">
              <h3>
                <span>УВАГА!</span> Повітряна тривога
              </h3>
            </div>
            <div className="attention-wrapper">
              <div className="small-wrapper">
                <div className="text-info">
                  <p>
                    Під час сигналу<span> “Повітряна тривога”</span> сеанс не
                    зупиняється.
                  </p>
                  <p> Знаходження в залі є добровільним.</p>
                  <p className="text-color">
                    {" "}
                    Не нехтуйте своєю безпекою! <br />
                    Облаштованого сховища на території кінотеатру немає!
                  </p>
                  <p> Найближчі укриття:</p>
                  <p>1. Вулиця Новомістенська, 30 (Школа № 10)</p>
                  <p>2. Вулиця Гетьмана Павла Скоропадського, 8 (Школа № 18)</p>
                </div>
              </div>

              <div className="small-wrapper">
                <div className="qr-code">
                  <img className="" src={QrCode} alt="" title="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewerInfo;
