const SocialResponsibility = () => {
  return (
    <div className="wrapper">
      <div className="heading-h2">
        <h2>Соціальна відповідальність</h2>
      </div>
      <div className="social-text-wrapper">
        <p>
          {" "}
          Будучи одним з провідних культурних закладів міста Сум та Сумської
          області, ТОВ “Кінотеатр Дружба” усвідомлює своє значення та соціальну
          відповідальність перед сумчанами!
        </p>
        <p>
          {" "}
          Кінотеатр Дружба завжди йде на зустріч громадським організаціям та
          закладам, чия робота – допомога соціально незахищеним верствам
          населення, дітям-сиротам, інвалідам, ветеранам, тощо. Маємо зручний
          заїзд до залу та пандус біля каси кінотеатру для людей, що
          пересуваються на інвалідних візках. Завжди гостинно приймаємо та
          намагаємось всіляко допомогти таким людям. Кінотеатр Дружба дуже цінує
          своїх маленьких глядачів! Саме вони – майбутнє нашої держави! Тому
          вирішено, що для дітлахів віком до 6 років перегляд мультфільмів в
          супроводі дорослих – безкоштовний. Вже традиційними в Кінотеатрі
          Дружба стали конкурси дитячих малюнків, розважальні та пізнавальні
          вікторини. Ми захоплюємося талантом наших дітлахів!{" "}
        </p>
        <p>
          {" "}
          Кінотеатр "Дружба" товаришує та співпрацює зі студентськими органами
          управління та надає всі студентам міста пільгові ціни на кіносеанси! В
          цей, найцікавіший період життя, студентство, має не тільки наполегливо
          навчатись за засвоювати знання, а й гарно відпочивати! Двері "Дружби"
          завжди відкриті! На сходах кінотеатру "Дружба" традиційно відбуваються
          міські заходи та святкування! Кінотеатр Дружба – культурний осередок
          центральних кварталів міста та Ковпаківського району! Зимовий сад в
          фойє другого поверху став у сумчан місцем відпочинку та численних
          фотосесій! Ми зажди раді приймати весілля! Живий куточок кінотеатру
          Дружба – це 3 акваріуми з рибами та черепахами, а також живі папуги!
        </p>
        <p className="text-center">
          {" "}
          Чекаємо на Вас, в найзатишнішому кінотеатрі міста Суми!
        </p>
      </div>
    </div>
  );
};

export default SocialResponsibility;
