import attentionInfo from "../../img/images/Attention.png"

const ActionBlock = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="action-h1-wrapper">
          <div className="heading-h1">
            <h1>Акції</h1>
          </div>
          <div className="action-wrapper">
            <div className="small-wrapper price-gap">
              <div className="heading-h3">
                <h3>Кінотеатр ДРУЖБА з 13 червня 2024р.</h3>
              </div>
              <div className="price-info">
                <p className="price-text">
                  У зв'язку з підвищенням мінімальної заробітної плати,
                  підвищенням вартості електроенергії та комунальних послуг з
                  01.12.2023р. в кінотеатрі Дружба діють наступні ціни та
                  пільги:
                </p>
              </div>

              <div className="price-info">
                <p className="price-name">Вартість квитків</p>
                <p className="price-price">120грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Вартість квитків{" "}
                  <span>на сеанси тривалістю більше 120 хв.</span>
                </p>
                <p className="price-price">150грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Для учасників бойових дій<span>*</span> вхід на кіносеанси 16+
                </p>
                <p className="price-price">
                  <span>Безкоштовно</span>
                </p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  В День Народження<span>*</span>
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Групове відвідування більше 20 осіб
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Для дитячих закладів{" "}
                  <span>у супроводі 2 дорослих (безкоштовно)</span>
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Щопонеділка учням 1-11 класів та студентам
                  <span>* (на період навчального року)</span>
                </p>
                <p className="price-price">90 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Для інвалідів І, ІІ, ІІІ групи<span>*</span>
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Власників єдиного квитка<span>*</span>
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Для багатодітних сімей<span>*</span>
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <div className="price-info">
                <p className="price-name">
                  Для внутрішньо перміщених осіб<span>*</span>:
                </p>
                <p className="price-price">100 грн</p>
              </div>

              <p className="price-name">
                1 дорослий + 1 дитячий <span>(БЕЗКОШТОВНО)</span> на дитячі
                сеанси (фільми, мультфільми) для: <br />
                1. Дітей до 6 років<span>*</span>
                <br />
                2. Дітей учасників бойових дій<span>*</span>
              </p>

              <p className="price-name">
                *Всі пільги діють за наявності підтверджуючого документу
              </p>
            </div>




          </div>

          <div className="action-wrapper">
            <div className="small-wrapper price-gap">
              <div className="heading-h3">
                <h3>Пільги на білети</h3>
              </div>
              <div className="price-info">
                <p className="price-name">
                 Пільги на білети для дітей <span>до 15 років</span> включно,
                 усіх пільгових категорій, не застосовуються на фільми,
                 які негативно пливають на дитячу психіку (<span>Жахи, ґорор, трилер</span>)
                </p>
              </div>

             
            </div>

            <div className="small-wrapper">
             
            </div>

            
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActionBlock;
