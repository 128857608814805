import React from "react";

import Detail from "./../components/detail/Detail";
import MovieMainBanner from "../components/movieMainBanner/MovieMainBaner";

import PriceDetails from "../components/pricedetails/PriceDetails";
import ActorsCarusel from "../components/actorsCarusel/ActorsCarusel";

const Details = () => {
  return (
    <div>
      <MovieMainBanner />

      <Detail />

     

      <ActorsCarusel />

      <PriceDetails />
    </div>
  );
};

export default Details;
